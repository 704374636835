import React, { useEffect } from "react";
import { supabase } from "./Supabase";
import FlipNumbers from "react-flip-numbers";


export function Like() {
    const [number, setNumber] = React.useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const { count, error } = await supabase.from('likes').select('*', { head: true, count: 'exact' });
            if (error) {
                return;
            }
            setNumber(count ?? 0);
        }
        fetchData();
    });

    async function handleSupport() {
        setNumber(number + 1);
        await supabase
            .from('likes')
            .insert([{}])
    }

    return (
        <div>
            <div className="flip-numbers">
                <div className="countContainer">累计</div>
                {number.toString().split('').map((digit, _) => (
                    <div className='flip-numbers-container' key={_}>
                        <FlipNumbers
                            width={18}
                            height={18}
                            color="#000"
                            play
                            numbers={digit}
                        />
                    </div>
                ))}
                <div className="supportContainer">人支持</div>
            </div>
            <div className='supportBtnContainer'>
                <div className='suppertBtn' onClick={handleSupport}>
                    支持
                </div>
            </div>
        </div>
    );
}