import './HomePage.css';

import { Songs } from "./Songs";
import { Banner } from "./Banner";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Activity } from "./Activity";
import { UserInfo } from "./UserInfo";
import { Like } from "./Like";

function HomePage() {
  return (
    <div className='appContainer'>
      <Banner></Banner>
      <UserInfo></UserInfo>
      <Like></Like>
      <Songs></Songs>
      <Activity></Activity>
      <SpeedInsights />
    </div>
  );
}

export default HomePage;
