import { useEffect, useState } from "react";
import './Activity.css';
import { supabase } from "./Supabase";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';

export interface ActivityBean {
    name: string
    description: string
    date: string
    location: string,
}

export function Activity() {
    const [activityList, setActivityList] = useState<ActivityBean[]>([]);
    const [length, setLength] = useState(0);
    const [page, setPage] = useState(0); // 当前页码
    const itemsPerPage = 10; // 每页显示的记录数

    // 获取活动列表
    const fetchActivities = async (page: number) => {
        const start = page * itemsPerPage;
        const end = start + itemsPerPage - 1;

        const { data, error } = await supabase
            .from("activities")
            .select()
            .range(start, end);

        if (error) {
            console.error("Error fetching activities:", error);
            return;
        }

        const curActivityList = data?.map((item) => ({
            name: item.name,
            description: item.description,
            date: item.date,
            location: item.location,
        }));

        setActivityList(curActivityList || []);
    };

    // 获取记录总数
    const fetchTotalLength = async () => {
        const { count, error } = await supabase
            .from("activities")
            .select("*", { head: true, count: "exact" });

        if (error) {
            console.error("Error fetching total count:", error);
            return;
        }

        setLength(count ?? 0);
    };

    useEffect(() => {
        fetchActivities(page); // 根据当前页码获取数据
        fetchTotalLength(); // 获取记录总数
    }, [page]);

    function handlePageClick(selectedItem: { selected: number }) {
        console.log(JSON.stringify(selectedItem))
        setPage(selectedItem.selected);
    }

    return (
        <div className="activity-container">
            <div className="title-container">最新资讯</div>
            <div className="list-container">
                {
                    activityList.map((item, index) => {
                        return (
                            <div className="item-container" key={index}>
                                <div className="line-container">
                                    <div className="circle-container">
                                        <div className="solid-circle"></div>
                                        <div className="transparent-circle"></div>
                                    </div>
                                    <div className="line"></div>
                                </div>
                                <div className="body-container">
                                    <div className="date-container">{item.date}</div>
                                    <div className="content-container">
                                        <div className="activity-title-container">{item.name}</div>
                                        <div className="subTitle-container">{item.description}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className="paginated-container">
                {
                    length >= itemsPerPage && <ReactPaginate
                        nextLabel="下一步"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(length / itemsPerPage)}
                        previousLabel="上一步"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                }
            </div>,
        </div>
    );
}

