import { useEffect, useState } from "react";
import { supabase } from "./Supabase";

import './UserInfo.css';

export interface UserInfoBean {
    stageName: string,
    biography: string,
}

export function UserInfo() {

    const [userInfo, setUserInfo] = useState<UserInfoBean>({ stageName: "", biography: "" });

    const fetchUser = async () => {
        const { data, error } = await supabase.from("artists").select("*").limit(1).single();
        if (error) {
            return;
        }
        const curUserInfo = {
            stageName: data.stage_name,
            biography: data.biography,
        };
        setUserInfo(curUserInfo);
    }

    useEffect(() => {
        fetchUser();
    });

    return (
        <div className="userInfoContainer">
            <div className='nameContainer'>
                {userInfo.stageName}
            </div>
            <div className='bioContainer'>
                {userInfo.biography}
            </div>
        </div>
    );
}