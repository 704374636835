import { useEffect, useState } from "react";
import { supabase } from "./Supabase";

import './Songs.css';
import { useNavigate } from "react-router-dom";

export interface SongBean {
    title: string
    image: string
}


export function Songs() {
    const navigator = useNavigate();
    const [songList, setSongList] = useState<SongBean[]>([]);
    useEffect(() => {
        supabase.from("songs").select().then((res) => {
            const { data, error } = res;
            if (error) {
                return;
            }
            const curSongList = data?.map((item, index) => {
                return {
                    title: item.title as string,
                    image: item.image as string,
                };
            });
            setSongList(curSongList);
        });
    }, [])

    function handleMore() {
        navigator('/songs');
    }

    return (
        <div className="song-container">
            <div className="title-container">
                <div>演唱作品</div>
                <div className="more-container" onClick={handleMore}>更多{'>'}</div>
            </div>
            <div className="grid-container">
                {songList.slice(0, Math.min(6, songList.length)).map((song, index) => (
                    <div key={index} className="grid-item">
                        <img className="img-container" src={song.image} />
                        <h3 className="song-title-container" >{song.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}